<template>
  <div>
    <b-card
      v-for="offer in offers"
      :key="offer"
    >
      <div class="employee">
        <div>
          {{ offer.title }} | {{ offer.price }}
        </div>
        <div>
          <router-link
            :to="{ name: 'Offer-show', params: { id: offer.id }}"
            class="btn btn-primary"
          >
            Podgląd
          </router-link>
        </div>
      </div>
    </b-card>
    <div v-if="!offers[0]">
      <b-card style="text-align:center;">
        <h1>
          Hej! Nie posiadasz jeszcze ofert, dodaj
          <router-link :to="{name: 'Offer-add'}">
            Tutaj
          </router-link>
          !
        </h1>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import swal from 'sweetalert2'
import axios from 'axios'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      offers: [],
    }
  },
  mounted() {
    document.title = 'Lista ofert - Pickmode'

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}my-offers`, config)
      .then(response => {
        this.offers = response.data.success
      })
      .catch(error => {
        swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      })
  },
}
</script>

<style>
.employee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.eye {
  margin-right: 10px;
  cursor: pointer;
}
.submit {
      width: 100%;
    height: 40px;
    border: 1px solid rgba(204, 204, 204, 0.344);
    border-radius: 5px;
    background: rgba(204, 204, 204, 0.468);
    margin-top: 10px;
}
</style>
